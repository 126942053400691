import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { NGROK } from "../../../APIs";

const AWSUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllUsers = async () => {
    const response = await axios.get(`${NGROK}/api/aws/getAllIAMUsers`);
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(`${NGROK}/api/aws/list-iam-groups`);
    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(`${NGROK}/api/aws/list-iam-roles`);
    return response.data;
  };

  const combinedPermissionandGetRoles = (users, roles) => {
    return users.map((user) => {
      const items = consolidatepermissions(user);
      const matchingRoles = [];

      roles.forEach((role) => {
        role?.inlinePolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });

        role?.attachedPolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });
      });

      user.roles = matchingRoles;

      return user;
    });
  };

  const fetchData = async () => {
    try {
      const [users, groups, roles] = await Promise.all([
        getAllUsers(),
        getAllGroups(),
        getAllRoles(),
      ]);

      let userWithRoles = combinedPermissionandGetRoles(users, roles);
      const combineData = {
        Users: userWithRoles,
        Groups: groups,
        Roles: roles,
      };
      setUsersData(combineData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const MAX_ITEMS_DISPLAYED = 2;

  const renderItemsWithMoreLink = (items, maxItems, user, groups) => {
    if (items.length <= maxItems) {
      return items.join(", ");
    } else {
      const displayedItems = items.slice(0, maxItems).join(", ");
      const remainingCount = items.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link to={`/aws/users/userDetails`} state={{ user, groups }}>
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const renderItemsWithMoreLinkAndConsolidating = (
    permissions,
    maxItems,
    user,
    groups,
  ) => {
    const items = consolidatepermissions(permissions);

    if (items.length <= maxItems) {
      return items.join(", ");
    } else {
      const displayedItems = items.slice(0, maxItems).join(", ");
      const remainingCount = items.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link to={`/aws/users/userDetails`} state={{ user, groups }}>
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const consolidatepermissions = (permissions) => {
    const consolidations = permissions
      ? [
          ...permissions.attachedUserPolicies,
          ...permissions.inlineUserPolicies,
          ...permissions.groupPolicies,
          ...permissions.groupInlinePolicies,
        ]
      : [];
    return consolidations;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = usersData
    ? usersData.Users.filter((user) =>
        user.userName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  if (loading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Stack spacing={5}>
        <Box display="flex" justifyContent="flex-end">
          <Stack direction={"row"} spacing={2}>
            <TextField
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/* <Button variant="outlined" color="primary">
              Create User
            </Button> */}
          </Stack>
        </Box>
        {!usersData ? (
          <Typography
            variant="body2"
            color="textSecondary"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            No datas available
          </Typography>
        ) : filteredUsers.length === 0 ? (
          <Typography
            variant="body2"
            color="textSecondary"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            No Users available
          </Typography>
        ) : (
          <TableContainer style={{ height: "fit-content" }} component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                maxWidth: "100%",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "16px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">User Name</TableCell>
                  <TableCell align="center">Groups</TableCell>
                  <TableCell align="center">Roles</TableCell>
                  <TableCell align="center">Permissions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow key={user.userName}>
                    <TableCell align="center">
                      <Link
                        to={`/aws/users/userDetails`}
                        state={{ user, groups: usersData.Groups }}
                      >
                        {user.userName}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {renderItemsWithMoreLink(
                        user.groups.map((group) => group.groupName),
                        MAX_ITEMS_DISPLAYED,
                        user,
                        usersData.Groups,
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderItemsWithMoreLink(
                        user.roles.map((role) => role.roleName),
                        MAX_ITEMS_DISPLAYED,
                        user,
                        usersData.Groups,
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderItemsWithMoreLinkAndConsolidating(
                        user,
                        MAX_ITEMS_DISPLAYED,
                        user,
                        usersData.Groups,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
};

export default AWSUsers;
