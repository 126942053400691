import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Paper,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import SecurityIcon from "@mui/icons-material/Security";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

const Wrapper = styled.div`
  max-height: 500px;
`;
const useStyles = makeStyles({
  root: {
    maxHeight: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const AwsRoles = () => {
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [modalPermission, setModalPermission] = useState(null);
  const [filter, setFilter] = useState("all");
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRolesDetails, setShowRolesDetails] = useState(false);
  const [isPermissionLoading, setIsPermissionLoading] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [filteredPermission, setFilteredPermission] = useState([]);
  const handleDetailsTabChange = (event, newValue) => {
    setSelectedDetailsTab(newValue);
  };

  const handlePermissionSearch = (event) => {
    const search = event.target.value;
    const filteredPermission = consolidatedPermissions.filter(
      (permission) =>
        permission.PolicyName &&
        permission.PolicyName.toLowerCase().includes(search.toLowerCase()),
    );
    console.log(filteredPermission);
    setFilteredPermission(filteredPermission);
  };

  const handleRoleSearchChange = (event) => {
    setSearch(event.target.value);
    setShowRolesDetails(false);
    setIsPermissionLoading(false);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    setShowRolesDetails(false);
    setIsPermissionLoading(false);
  };

  const getTheSpecificPolicyDocument = async (policyArn) => {
    const data = `policyArn=${encodeURIComponent(policyArn)}`;

    try {
      const response = await axios.post(
        `${NGROK}/api/aws-policies/by-arn`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      return response.data;
    } catch (error) {
      return null;
    }
  };
  const handlePermissionClick = (permission) => {
    setSelectedPermission(permission.PolicyArn);
  };

  const handlePolicyInfoOpen = async (permission) => {
    setIsPermissionLoading(true);
    const permissionDoc = await getTheSpecificPolicyDocument(
      permission.PolicyArn,
    );

    if (permissionDoc) {
      setModalPermission(permissionDoc[0]);
      setShowRolesDetails(true);
    } else {
      setShowRolesDetails(false);
    }
    setIsPermissionLoading(false);
  };

  const handlePolicyInfoClose = () => {
    setModalPermission(null);
  };

  const handleFilterChange = (event, newFilter) => {
    const filteredRoles = filterRoles(roles, newFilter);
    setFilter(newFilter);
    setSelectedRole(filteredRoles.length > 0 ? filteredRoles[0] : null);
    setShowRolesDetails(false);
    setIsPermissionLoading(false);
    setSelectedPermission(null);
  };

  const getAllUsers = async () => {
    const response = await axios.get(`${NGROK}/api/aws/getAllIAMUsers`);

    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(`${NGROK}/api/aws/list-iam-groups`);

    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(`${NGROK}/api/aws/list-iam-roles`);
    return response.data;
  };

  const fetchData = async (initialLoad = false) => {
    try {
      const [users, groups, roles] = await Promise.all([
        getAllUsers(),
        getAllGroups(),
        getAllRoles(),
      ]);
      setUsers(users);
      setGroups(groups);
      setRoles(roles);
      setLoading(false);
      if (initialLoad) {
        const filteredRoles = filterRoles(roles, filter);
        setSelectedRole(filteredRoles.length > 0 ? filteredRoles[0] : null);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const filterRoles = (roles, filter) => {
    if (filter === "default") {
      return roles.filter((role) =>
        role.roleName.startsWith("AWSServiceRoleFor"),
      );
    } else if (filter === "custom") {
      return roles.filter(
        (role) => !role.roleName.startsWith("AWSServiceRoleFor"),
      );
    }
    return roles;
  };

  const filteredRoles = filterRoles(
    roles.filter(
      (role) =>
        role.roleName &&
        role.roleName.toLowerCase().includes(search.toLowerCase()),
    ),
    filter,
  );

  const consolidatedPermissions = selectedRole
    ? [
        ...selectedRole.attachedPolicies,
        ...selectedRole.inlinePolicies.map((permission) => ({
          policyArn: "Inline Policy",
          policyName: permission,
        })),
      ]
    : [];
  useEffect(() => {
    if (selectedRole) {
      setFilteredPermission(consolidatedPermissions);
    }
  }, [selectedRole]);

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontSize: "22px",
          }}
        >
          IAM Roles
        </Typography>
        {loading ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack spacing={5} mt={2}>
              <Box display="flex" justifyContent="space-between">
                <ToggleButtonGroup
                  value={filter}
                  exclusive
                  onChange={handleFilterChange}
                  aria-label="filter toggle buttons"
                >
                  <ToggleButton
                    value="all"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="default"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Default
                  </ToggleButton>
                  <ToggleButton
                    value="custom"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Custom
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Stack direction="row" spacing={5}>
                <Box width="30%">
                  {filteredRoles.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          height: "fit-content",
                          "& th": {
                            background: "#233044",
                            color: "#fff",
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "16px",
                          },
                        }}
                        size="large"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Roles Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <TextField
                                label="Search Roles"
                                variant="outlined"
                                value={search}
                                onChange={handleRoleSearchChange}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                          {filteredRoles.map((role) => (
                            <TableRow
                              key={role.roleId}
                              onClick={() => handleRoleClick(role)}
                              selected={role.roleId === selectedRole?.roleId}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "16px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>{role.roleName}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="h6">No Roles Found</Typography>
                  )}
                </Box>
                <Box width="30%">
                  {selectedRole ? (
                    <Stack spacing={5}>
                      {filteredPermission.length > 0 ? (
                        <TableContainer>
                          <Table
                            sx={{
                              height: "fit-content",
                              "& th": {
                                background: "#233044",
                                color: "#fff",
                              },
                              "& td, & th": {
                                border: "1px solid #233044",
                                fontSize: "16px",
                              },
                            }}
                            size="large"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Permission Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <TextField
                                    label="Search permissions"
                                    variant="outlined"
                                    onChange={handlePermissionSearch}
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                              {filteredPermission.length > 0 ? (
                                filteredPermission.map((permission, index) => (
                                  <TableRow
                                    selected={
                                      selectedPermission ===
                                      permission.policyArn
                                    }
                                    key={permission.policyArn}
                                    onClick={() => {
                                      //handlePermissionClick(permission);
                                      //handlePolicyInfoOpen(permission);
                                    }}
                                    sx={{
                                      "&:hover": {
                                        background: "#f5f5f5",
                                        cursor: "pointer",
                                      },
                                      "& td, & th": {
                                        border: "1px solid #233044",
                                        fontSize: "16px",
                                      },
                                      "&.Mui-selected": {
                                        background: "#233044 !important",
                                        "& td, & th": {
                                          color: "#fff",
                                        },
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      <Stack>
                                        <Typography variant="h5">
                                          {permission.policyName}
                                        </Typography>
                                        <Typography variant="body1">
                                          {permission.policyArn}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell>
                                    <Typography variant="h5">
                                      No permissions found for this role.
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography variant="h5">
                          No permissions found for this role.
                        </Typography>
                      )}
                    </Stack>
                  ) : (
                    <Typography variant="body2">
                      Select a role to view permissions.
                    </Typography>
                  )}
                </Box>
                <Box width="40%">
                  {isPermissionLoading ? (
                    <CircularProgress />
                  ) : (
                    selectedRole &&
                    showRolesDetails && (
                      <Box
                        sx={{
                          border: "1px solid",
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            background: "#233044",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "16px",
                            p: 4.8,
                          }}
                        >
                          Role Details
                        </Typography>
                        <>
                          <Tabs
                            value={selectedDetailsTab}
                            onChange={handleDetailsTabChange}
                          >
                            <Tab
                              icon={<PersonIcon />}
                              label={
                                <Typography sx={{ fontSize: 16 }}>
                                  Users ({users.length})
                                </Typography>
                              }
                            />
                            <Tab
                              icon={<GroupsIcon />}
                              label={
                                <Typography sx={{ fontSize: 16 }}>
                                  Groups ({groups.length})
                                </Typography>
                              }
                            />
                            <Tab
                              icon={<SecurityIcon />}
                              label={
                                <Typography sx={{ fontSize: 16 }}>
                                  Permissions
                                </Typography>
                              }
                            />
                          </Tabs>
                          {selectedDetailsTab === 0 && (
                            <List>
                              {users.map((user) => (
                                <ListItem key={user.UserId}>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 16 }}>
                                        {user.UserName}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 16 }}>
                                        {user.Arn}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          )}
                          {selectedDetailsTab === 1 && (
                            <List>
                              {groups.map((group) => (
                                <ListItem key={group.GroupId}>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 16 }}>
                                        {group.GroupName}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 16 }}>
                                        {group.Arn}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          )}
                          {selectedDetailsTab === 2 && (
                            <Box
                              mt={5}
                              sx={{
                                margin: "10px",
                                padding: "10px",
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Attached Permissions:</strong>
                              </Typography>

                              <List>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 16 }}>
                                        <strong>
                                          Policy Name:{" "}
                                          {modalPermission.PolicyName}
                                        </strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 16 }}>
                                        {modalPermission.PolicyArn}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                              <Typography sx={{ fontSize: 16 }}>
                                <strong>Policy Document:</strong>
                              </Typography>
                              <Wrapper className={classes.root}>
                                {modalPermission.PolicyDocument ? (
                                  <Paper
                                    elevation={3}
                                    style={{ padding: "10px", margin: "10px" }}
                                  >
                                    <Typography sx={{ fontSize: 16 }}>
                                      <strong>Version:</strong>{" "}
                                      {modalPermission.PolicyDocument.Version}
                                    </Typography>

                                    {modalPermission.PolicyDocument.Statement.map(
                                      (statement, index) => (
                                        <Grid
                                          container
                                          key={index}
                                          spacing={2}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="body2"
                                              sx={{ fontSize: 16 }}
                                            >
                                              <strong>
                                                Statement {index + 1}:
                                              </strong>
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography sx={{ fontSize: 16 }}>
                                              <strong>Sid:</strong>{" "}
                                              {statement.Sid}
                                            </Typography>
                                            <Typography sx={{ fontSize: 16 }}>
                                              <strong>Effect:</strong>{" "}
                                              {statement.Effect}
                                            </Typography>
                                            <Typography sx={{ fontSize: 16 }}>
                                              <strong>Action:</strong>{" "}
                                              <List disablePadding>
                                                {statement.Action?.map(
                                                  (action, index) => (
                                                    <ListItem
                                                      key={index}
                                                      disableGutters
                                                      sx={{ padding: 0 }}
                                                    >
                                                      <ListItemText>
                                                        <Typography
                                                          sx={{ fontSize: 16 }}
                                                        >
                                                          {action}
                                                        </Typography>
                                                      </ListItemText>
                                                    </ListItem>
                                                  ),
                                                )}
                                              </List>
                                            </Typography>
                                            <Typography sx={{ fontSize: 16 }}>
                                              <strong>Resource:</strong>{" "}
                                              {Array.isArray(
                                                statement.Resource,
                                              ) ? (
                                                <List>
                                                  {statement.Resource?.map(
                                                    (resource, index) => (
                                                      <ListItem
                                                        key={index}
                                                        disableGutters
                                                        sx={{ padding: 0 }}
                                                      >
                                                        <ListItemText>
                                                          <Typography
                                                            sx={{
                                                              fontSize: 16,
                                                            }}
                                                          >
                                                            {resource}
                                                          </Typography>
                                                        </ListItemText>
                                                      </ListItem>
                                                    ),
                                                  )}
                                                </List>
                                              ) : (
                                                <Typography
                                                  sx={{ fontSize: 16 }}
                                                >
                                                  {statement.Resource}
                                                </Typography>
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ),
                                    )}
                                  </Paper>
                                ) : (
                                  <Typography sx={{ fontSize: 16 }}>
                                    No policy document available
                                  </Typography>
                                )}
                              </Wrapper>
                            </Box>
                          )}
                        </>
                      </Box>
                    )
                  )}
                </Box>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default AwsRoles;
