import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import AWSRequestAccessForm from "./AWSRequestAccessForm";
import useUserStore from "../../../../services/userStore";

const AccessAWSGroups = ({ user, groups }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onAWSRequestAccessForm, setOnAWSRequestAccessForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { role, userId } = useUserStore((state) => state.user);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClickRequestForm = (group) => {
    console.log(group);
    setSelectedGroup(group);
    setOnAWSRequestAccessForm(true);
  };
  const getSpecificUserGroup = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIAMUsers?name=${user.userName}`,
    );
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(`${NGROK}/api/aws/list-iam-groups`);
    return response.data;
  };

  const fetchAllGroups = async (isFirst = false) => {
    if (role === "TENANT_USER") {
      if (isFirst) setLoading(true);
      try {
        const [specificUsersGroup, allGroups] = await Promise.all([
          getSpecificUserGroup(),
          getAllGroups(),
        ]);

        const updatedGroups = allGroups.map((group) => ({
          ...group,
          alreadyInGroup: specificUsersGroup[0].groups.some(
            (g) => g.groupId === group.groupId,
          ),
        }));

        setAllGroups(updatedGroups);
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setAllGroups(groups);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGroups(true);
    const interval = setInterval(fetchAllGroups, 5000);
    return () => clearInterval(interval);
  }, [user, groups]);

  const filteredGroups = allGroups.filter((group) =>
    group.groupName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (loading) return <CircularProgress />;

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
                fontSize: "16px",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell>Groups</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups.length > 0 ? (
                <>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Groups"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </TableCell>
                  </TableRow>
                  {filteredGroups.map((group, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={role === "TENANT_USER" ? 1 : 2}>
                        {group.groupName}
                      </TableCell>
                      {role === "TENANT_USER" ? (
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={group.alreadyInGroup}
                            onClick={() => {
                              handleClickRequestForm(group);
                            }}
                          >
                            Request Access
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {selectedGroup && onAWSRequestAccessForm && (
        <AWSRequestAccessForm
          open={onAWSRequestAccessForm}
          onClose={() => {
            setOnAWSRequestAccessForm(false);
            setSelectedGroup(null);
          }}
          data={selectedGroup}
          title={selectedGroup?.groupName}
          user={user}
          from={"groups"}
        />
      )}
    </>
  );
};

export default AccessAWSGroups;
